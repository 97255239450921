import React from "react";
import {useSelector } from "react-redux";

const CheckoutPrice = () => {
    const price = useSelector(state => state.price);

    return (
        price &&
        <>
            <h3 className="text-gray-500 text-xs font-medium uppercase tracking-wide pt-4">Pris</h3>
            <div className="mt-3">
                {price} SEK <span className="text-xs">inkl. moms</span>
            </div>
        </>
    );
};

export default CheckoutPrice;
