import React from "react";
import { useFormContext } from "react-hook-form";


export default function AcceptTerms() {
    const {register, formState: {errors}} = useFormContext();

    return (
        <>
            <div className="flex items-center mb-4">
                <input name="acceptTerms" type="checkbox" {...register('acceptTerms', {required: true})} id="acceptTerms" className={`w-4 h-4 mr-2 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600 form-check-input ${errors.acceptTerms ? 'is-invalid' : ''}`} />
                <label htmlFor="acceptTerms" className="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300">
                    Jag godkänner <a href="https://nassjojarnvagsmuseum.se/resevillkor/" target="_blank" className="text-blue-600 dark:text-blue-500 hover:underline">bokningsvillkoren</a>.
                </label>
            </div>
            {errors.acceptTerms && (
                <div className="invalid-feedback text-red-800 text-sm mb-2">
                    Du måste godkänna våra bokningsvillkor för att kunna slutföra bokningen.
                </div>
            )}
        </>
    );
}
