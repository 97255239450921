import http from "../http-common";

const getAll = () => {
    return http.get("/trains");
};

const get = id => {
    return http.get(`/trains/${id}`);
};

const check = id => {
    return http.get("/train-check/" + id)
    .then(response => {
        return response.data;
    });
}

const TrainService = {
    getAll,
    get,
    check
};

export default TrainService;
